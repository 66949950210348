.footer_container {
    color: white;
    font-size: 13px;
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 30px;
    overflow: hidden;
}

@media (max-width: 780px) {
    .footer_container {
        flex-direction: column;
        gap: 20px;
        padding: 15px;
    }
}

.footer_first_div {
    width: 28%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

@media (max-width: 780px) {
    .footer_first_div {
        flex-direction: row;
        width: 100%;
        gap: 20px;
    }
}

.footer_second_div {
    width: 18%;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

@media (max-width: 780px) {
    .footer_second_div {
        width: 100%;
    }
}

@media (max-width: 1100px) {
    .explore {
        display: none;
    }
}

.footer_third_div {
    width: 18%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

@media (max-width: 780px) {
    .footer_third_div {
        width: 100%;
        gap: 10px;
    }
}

.footer_fourth_div {
    width: 28%;
    display: flex;
    gap: 10px;
    justify-content: space-between;
    align-items: flex-end;
}

@media (max-width: 780px) {
    .footer_fourth_div {
        /* display: none; */
        /* Equivalent to hidden */
    }
}