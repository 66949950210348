.about_container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 20px;
    overflow: hidden;
}

@media (max-width: 780px) {
    .about_container {
        flex-direction: column;
        gap: 10px;
    }
}

.about_first_div {
    width: 35%;
    display: flex;
    /* align-items: center; */
    justify-content: center;
    flex-direction: column;
    gap: 20px;
}

@media (max-width: 780px) {
    .about_first_div {
        width: 100%;
    }
}

@media (max-width: 720px) {
    .about_first_div {
        gap: 10px;
    }
}

.about_tacit_second_div {
    width: 60%;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
}

@media (max-width: 720px) {
    .about_tacit_second_div {
        width: 100%;
    }
}
@media (min-width: 1600px) {
    .about_tacit_second_div {
        width: 40%;
    }
}

.about_tacit_second_div_image {
    width: 435px;
    height: 533px;
    position: absolute;
}

@media (max-width: 780px) {
    .about_tacit_second_div_image { 
        width: 90%;
        height: auto;
        /* Use auto to set height to full based on content */
     } 
}