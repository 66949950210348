@media (min-width: 720px) {
    .hamBurg {
        display: none;
    }
}

.header_buttons {
    display: flex;
    /* width: 40%; */
    /* justify-content: space-between; */
    gap: 40px;
    align-items: center;
    font-weight: 600;
    /* Equivalent to font-semibold */
    font-size: 20px;
}

@media (max-width: 780px) {
    .header_buttons {
        /* width: 60%; */
        gap: 20px;
    }
}

@media (max-width: 720px) {
    .header_buttons {
        display: none;
        /* Equivalent to hidden */
    }
}