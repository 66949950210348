textarea {
    resize: none;
}

@media (max-width: 800px) {
    .contact_us_map {
        /* width: 530px; */
        height: 270px;
    }
}

@media (max-width: 600px) {
    .contact_us_map {
        /* width: 270px; */
        height: 200px;
    }
}

@media (max-width: 600px) {
    .countact_us_below_map {
        flex-direction: column;
        gap: 20px;
    }
}

@media (max-width: 600px) {
    .countact_us_below_map_single {
        width: 100%;
        border: none;
        justify-content: flex-start;
    }
}

@media (max-width: 700px) {
    .form_container {
        width: 100%;
    }
}

@media (max-width: 700px) {
    .first_last_name {
        flex-direction: column;
    }
}

@media (max-width: 700px) {
    .first_name {
        width: 100%;
    }
}