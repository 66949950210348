@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

.custom-dot::marker {
    color: #0AC4FF;
    font-size: 12px;
}

.fade-in {
    animation: fadeIn 0.5s ease-in-out forwards;
}

.fade-out {
    animation: fadeOut 0.5s ease-in-out forwards;
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@keyframes fadeOut {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}

.dynamic_text {
    position: absolute;
    width: 85%;
    backdrop-filter: blur(8px);
    background-color: rgba(255, 255, 255, 0.1);
    border-radius: 4px;
    font-size: 51px;
    font-weight: bold;
    text-align: center;
    padding-top: 40px;
    padding-bottom: 40px;
    bottom: 10%;
}
/* md:max-lg:text-[30px] max-[770px]:font-semibold max-[720px]:py-[20px] md:max-lg:py-[30px] min-[1400px]:bottom-[13%] min-[1540px]:bottom-[18%] min-[1640px]:bottom-[21%] min-[1800px]:bottom-[25%] max-[720px]:flex flex-col max-[720px]:leading-[22px] */
@media (max-width: 770px) {
    .dynamic_text {
        width: 90%;
        font-size: 25px;
        font-weight: 600;
        padding-top: 20px;
        padding-bottom: 20px;
        display: flex;
        flex-direction: column;
        line-height: 22px;
    }
}

@media (min-width: 1400px) {
    .dynamic_text {
        bottom: 13%;
        /* Adjusted position for larger screens */
    }
}

@media (min-width: 1540px) {
    .dynamic_text {
        bottom: 18%;
        /* Further adjusted position */
    }
}

@media (min-width: 1640px) {
    .dynamic_text {
        bottom: 21%;
        /* Further adjusted position */
    }
}

@media (min-width: 1800px) {
    .dynamic_text {
        bottom: 25%;
        /* Further adjusted position */
    }
}

@media (max-width: 720px) {
    .dynamic_text {
        padding-top: 30px;
        /* Adjusted padding for medium screens */
        padding-bottom: 30px;
        /* Adjusted padding for medium screens */
    }
}