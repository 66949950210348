.our_services_heading_div {
    width: 60%;
}

@media (max-width: 901px) {
    .our_services_heading_div {
        width: 100%;
    }
}

.our_services_slider {
    position: relative;
    /* height: 100vh; */
    /* Equivalent to h-screen */
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

@media (max-width: 900px) {
    .our_services_slider {
        display: none;
        /* Equivalent to hidden */
    }
}

.our_services_slider_img_div {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 10px;
    /* Equivalent to py-10 */
    padding-bottom: 10px;
    /* Equivalent to py-10 */
}

@media (max-width: 1000px) {
    .our_services_slider_img_div {
        padding-top: 0;
        padding-bottom: 0;
    }
}

.our_services_slider_img {
    width: 680px;
    height: 410px;
}

@media (max-width: 1000px) {
    .our_services_slider_img {
        width: 500px;
        height: 380px;
    }
}

.our_services_slider_options {
    font-size: 22px;
}

@media (max-width: 1000px) {
    .our_services_slider_options {
        font-size: 18px;
    }
}

.our_services_slider_options_para {
    font-size: 15px;
    padding-top: 10px;
}

@media (max-width: 1000px) {
    .our_services_slider_options_para {
        font-size: 13px;
        padding-top: 5px;
    }
}

.mobile_slider_container {
    position: relative;
    width: 100%;
    overflow: hidden;
}

@media (min-width: 901px) {
    .mobile_slider_container {
        display: none;
        /* Equivalent to hidden */
    }
}