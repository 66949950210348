.services_page_heading_div {
    width: 35%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

@media (max-width: 700px) {
    .services_page_heading_div {
        width: 60%;
    }
}

@media (max-width: 400px) {
    .services_page_heading_div {
        width: 100%;
    }
}

@media (max-width: 500px) {
    .services_page_heading_div {
        gap: 10px;
    }
}

.web_dev_services_page_heading_div {
    width: 60%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

@media (max-width: 700px) {
    .web_dev_services_page_heading_div {
        width: 80%;
    }
}

@media (max-width: 400px) {
    .web_dev_services_page_heading_div {
        width: 100%;
    }
}

@media (max-width: 500px) {
    .web_dev_services_page_heading_div {
        gap: 10px;
    }
}

.services_page_left {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

@media (max-width: 695px) {
    .services_page_left {
        flex-direction: column;
    }
}

@media (max-width: 695px) {
    .service_image_div {
        display: none;
    }
}

@media (min-width: 695px) {
    .service_image_div_mobile {
        display: none;
    }
}

@media (max-width: 525px) {
    .service_image {
        width: 460px;
        height: 350px;
    }
}

@media (max-width: 470px) {
    .service_image {
        width: 400px;
        height: 300px;
    }
}

@media (max-width: 400px) {
    .service_image {
        width: 310px;
        height: 210px;
    }
}

@media (max-width: 550px) {
    .service_image_title {
        font-size: 34px;
    }
}

@media (max-width: 550px) {
    .service_image_des {
        font-size: 20px;
    }
}

@media (max-width: 400px) {
    .service_image_title {
        font-size: 24px;
    }
}

@media (max-width: 400px) {
    .service_image_des {
        font-size: 16px;
    }
}